<template>
  <div class="about-us-wrap h-100">
    <div class="about-us-center">
      <div class="about-us-center-title">
        <h1 class="title flex cen-center">关于我们</h1>
      </div>
    </div>

    <div class="about-us-con">
      <div class="title">
        <div>公司介绍</div>
      </div>

      <div class="text">火花音悦成立于2020年，隶属于北京火星火翎文化科技有限公司，是一家专注于音乐商用解决方案、音乐版权管理及音乐作品营销的创新型服务科技企业。</div>
      <div class="text">
        {{
        `火花音悦已完成数百万种子轮融资，投资方包括蓝港互动（8267.HK)、唯猎资本、东湖天使基金等。同时已完成逾四十万首罐头音乐和数千首vocal流行音乐的一级代理曲库搭建，旗下版权音乐平台「SPARK.ART」1.0也进入测试阶段。`
        }}
      </div>
      <div
        class="text"
      >和市场上其他音乐授权平台不同，SPARK.ART更在意原创作品的潜在价值挖掘和内容的精细化运营。所有作品皆由专业制作人录入双重验收，充分保障准确率，并结合消费场景搭建了数百歌单SPU</div>
      <div
        class="text"
      >同时，公司在内容端搭建了更高效的音乐版权供应链，代理了音乐作品的全版权以及对授权的定价权，搭建了电商零售、代理、包年、买断等多种商商业模型，最大化控制成本，帮助客户在同样预算下，能更快匹配适合的内容服务、获得更好的音乐体验。</div>

      <!-- <div class="title mt32">创始人介绍</div>
      <div class="text">陈鑫</div>
      <div class="text">火花音悦创始人、CEO</div>
      <div class="text">
        毕业于江苏海洋大学，2014年加入蓝港互动（8267.HK)，后任公关总监。
      </div>
      <div class="text">
        2018年关注并调研国内音乐版权市场，后加入VFineMusic任副总裁负责经营，经手的「短视频商用音乐侵权第一案」多次登上微博热送，唤醒全民对正版音乐的保护，其对于音乐版权保护和商业开发的热情，获得龚琳娜、老锣、张蔷等知名音乐人和艺术家的肯定。
      </div>
      <div class="text">2020年10月，选择创业，创办火花音悦。</div>
      <ng-image class="ceo-img" :src="CeoImage" alt="thumbnail" />
      -->
      <div class="title mt32">企业愿景</div>
      <div
        class="text"
      >「音乐创造价值」是火花音悦的价值观，让天下没有被埋没的好作品是我们的愿景。在中国音乐版权正版化的浪潮下，团队不仅致力于让消费者和企业用上更具性价比的内容，更重要的是帮助更多原创音乐获得收入，从源头上提升原创音乐的生命力和创造力，重振中国音乐产业的荣光。</div>

      <div id="contactUs" class="title mt32">联系我们</div>
      <div class="text">官方客服：13611227841（工作日： 9:30-18:30，节假日请留言）</div>
      <div class="text">授权咨询：support@spark.art</div>
      <div class="text">企业服务：13651244786（陈先生）</div>
      <div class="text">音乐入驻：tim@spark.art</div>
      <div class="text">音乐发行：18510856628（王女士）</div>
      <div class="text">品牌合作：bd@spark.art </div>
      <div class="text">官方微博：SparkMusic火花音悦</div>
      <div class="text">官方微信公众号：火花音悦黑板报</div>
      <div class="text">地址：北京市朝阳区鼓楼外大街19号B幢5层522室</div>
    </div>
  </div>
</template>

<script>
  import CeoImage from "@/assets/images/ceo-img.png";
  export default {
    data() {
      return {
        CeoImage,
      };
    },
    components: {
    },

    mounted() {
      this.$root.jumpAnchor();
    },

    NgImagecreated() { },

    methods: {
      handleHelpTab(e) {
        console.log("e: ", e);
        this.activeTab = e;
        this.activeCollapse = [];
      },
      changeTab() {
        this.activeCollapse = [];
      },
      changeActivekey(e) {
        console.log("e: ", e);
      },
      handleClick(event) {
        // If you don't want click extra trigger collapse, you can prevent this:
        event.stopPropagation();
      },
    },
  };
</script>

<style lang="scss" scope>
  .about-us-wrap {
    width: 100%;
    .mt32 {
      margin-top: 3.2rem;
    }
    .mt20 {
      margin-top: 2rem;
    }
    .mt10 {
      margin-top: 1rem;
    }
    .mr8 {
      margin-right: 0.8rem;
    }
    .color-55 {
      color: rgba(0, 0, 0, 0.55);
    }
    .color-85 {
      color: rgba(0, 0, 0, 0.85);
    }
    // padding-bottom: 3.2rem;

    .about-us-center {
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 12.8rem;
      background: rgba(0, 0, 0, 0.02);
      .about-us-center-title {
        width: 765px;
        .title {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          margin: 0;
          font-size: 3.2rem;
        }
      }
    }
    .about-us-con {
      width: 765px;
      margin: 3.2rem auto 0 auto;
      .title {
        font-size: 2.4rem;
        font-weight: bold;
        color: rgba(0, 0, 0, 0.85);
      }
      .text {
        margin-top: 0.8rem;
        font-size: 1.4rem;
        color: rgba(0, 0, 0, 0.55);
      }
      .ceo-img {
        width: 20rem;
        height: 27.3rem;
        margin-top: 0.8rem;
      }
    }
  }

  // // mobile
  @media screen and (max-width: $screen-width-md) {
    .about-us-wrap {
      width: 100%;
      .about-us-center {
        .about-us-center-title {
          width: 100%;
          padding: 0 1rem;
        }
      }
      .about-us-con {
        padding: 0 1rem;
        width: 100%;
      }
    }
  }

  // pc
  @media screen and (min-width: $screen-width-md) {
  }
</style>
